import React from "react"
import HeaderV2 from "../headerv2/headerv2"
import Hero from "../../microcomponents/hero/hero"
import LeftRightImageText from "../../microcomponents/leftrightimagetext/leftrightimagetext"
import Image from "../../components/image-component/image-2023"
import Button from "../../microcomponents/button/button"
import { ArrowIcon } from "../../microcomponents/icons"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import ClientResults from "../../microcomponents/clientresults/clientresults"
import Container from "../../microcomponents/container/container"
import HeadSubhead from "../../microcomponents/headsubhead/headsubhead"
import "./ecommerceconversionoptimization.scss"
import ClientLogosLp from "../../microcomponents/LP/clientlogoslp"
import { faqEcommerceLandingPage } from "../../utilities/faqs"
import FrequentlyAskedQuestionsTyping from "../frequentlyaskedquestionstyping/frequentlyaskedquestionstyping"
import ClientLogos from "../../microcomponents/clientlogos/clientlogos"
import FrequentlyAskedQuestions from "../frequentlyaskedquestions/frequentlyaskedquestions"
export default function EcommerceOptimizationAgency() {
  return (
    <div className="ecom-lp-wrapper">
      <HeaderV2 version="v2" />
      <Hero
        version="v2"
        className={`blueeclipse ecomlp-hero`}
        heading="Grow sales & revenue 15% in 90 days. <span>Guaranteed.</span>"
        description="<p>Our prediction engine scrapes thousands of eCommerce tests to find what works for your site. Full service team that handles all dev and research. More conversions, more sales.</p>"
        heroImage={`desktop_background_1.webp`}
        subHeading="eCommerce Conversion Optimization Agency"
        // video={`//res.cloudinary.com/spiralyze/video/upload/spiralyze2022/VIDEOS/Hero_animation_Single_screen_version.mp4`}
        sourceFolder={`spzlps`}
        cloudinaryPath={`https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/`}
        heroCtaIcon={null}
        tags={[
          "Prediction Engine",
          "eCommerce Conversion Team",
          "Expert Insights",
          "No Results, No Payment",
        ]}
        customLogoComponent={
          <ClientLogosLp
            version="v2 pd-40-bottom side-fades hidetablet hideipad hidemobile"
            sourceFolder="lp"
            // cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            logos={[
              {
                name: "Logo.svg",
                alt: "Gusto",
                pictureTagIndexes: [],
              },
              {
                name: "Logo-1.svg",
                alt: "Podium",
                pictureTagIndexes: [],
              },
              {
                name: "Logo-2.svg",
                alt: "Arctic Wolf",
                pictureTagIndexes: [],
              },
              {
                name: "Logo-3.svg",
                alt: "Unbounce",
                pictureTagIndexes: [],
              },
              {
                name: "Logo-5.svg",
                alt: "Crowd Strike",
                pictureTagIndexes: [],
              },
              {
                name: "Logo-4.svg",
                alt: "Filevine",
                pictureTagIndexes: [],
              },
              {
                name: "Logo-6.svg",
                alt: "EcoOnline",
                pictureTagIndexes: [],
              },
              {
                name: "Logo-7.svg",
                alt: "Velocity Global",
                pictureTagIndexes: [],
              },
            ]}
          />
        }
      />

      <ClientLogos version="v2 hidedesktop" />

      <LeftRightImageText
        mainHeading="Pattern your product pages from winners."
        subHeading="Prediction Engine"
        sectionDescription="<p>Our scraper crawls A/B tests from 78,000 websites to find the ones that work best for eCommerce. Our machine learning predicts what will work best on your site.</p><p><b>Get 3x more wins than other eCommerce CRO agencies.</b></p>"
        // innerClasses="pd-56-tablet-top"
        // wrapperClasses="absolute-image-section"
        innerClasses="absolute-image-section ecom-lp"
        headingTag="h3"
        wrapperClasses="pd-56-tablet-top gray-bg"
        id="predictionengine"
        imageComponent={
          <>
            <Image
              sourceFolder={"spzlps"}
              lazyLoad={"true"}
              fallBackImage={`prediction_engine_image_5.webp`}
              // imgName={`prediction_engine_image_3.webp`}
              alt={`Research`}
              imgName={[
                {
                  imageName: "prediction_engine_image_5.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "prediction_engine_image_t_1.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "prediction_engine_image_m.webp",
                  minBreakpoint: "250px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          version={"v2 fit-content"}
          href="/pricing/"
          //   icon={<ArrowIcon />}
          //   type={`cta`}
        >
          Get a demo
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="We do everything. You focus on your business."
        subHeading="Ecommerce Optimization Agency"
        sectionDescription="<p>Our full service team handles everything from design, copy, and development to QA, analytics, and project management. Launch your first test in 2 weeks. Run 3x more tests. </p>"
        imageFolder="how_it_works"
        sectionImage="howitworksimage1.webp"
        innerClasses="reverse absolute-image-section ecom-lp"
        wrapperClasses="pd-56-tablet-top"
        id="ecommerceconversionteam"
        headingTag="h3"
        imageComponent={
          <div className="">
            <Image
              sourceFolder={"spzlps"}
              lazyLoad={"true"}
              fallBackImage={`ecommerce_optimization_agency_image_2.webp`}
              // imgName={`ecommerce_optimization_agency_image_2.webp`}
              alt={`Research`}
              imgName={[
                {
                  imageName: "ecommerce_optimization_agency_image_2.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "ecommerce_optimization_agency_image_1.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "ecommerce_optimization_agency_image.webp",
                  minBreakpoint: "250px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </div>
        }
      >
        <Button
          version={"v2 fit-content"}
          href="/pricing/"
          icon={<ArrowIcon />}
          //   type={`cta`}
        >
          Meet Your CRO Experts
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="See what stops users from buying."
        subHeading="data insights"
        sectionDescription="<p>Get actionable customer insights from product page analytics, exit polls, session recordings, heatmaps, review mining & analysis, message testing, and more. </p><p>Our team uses the insights to optimize messaging, design, and other elements to increase commitment and boost conversions.</p>"
        // innerClasses="pd-56-tablet-top"
        // wrapperClasses="absolute-image-section"
        innerClasses="absolute-image-section ecom-lp"
        headingTag="h3"
        wrapperClasses="pd-56-tablet-top gray-bg"
        id="expertinsights"
        imageComponent={
          <>
            <Image
              sourceFolder={"spzlps"}
              lazyLoad={"true"}
              fallBackImage={`prediction_engine_image_10.webp`}
              // imgName={`ecommerce_optimization_agency_image_2.webp`}
              alt={`Research`}
              imgName={[
                {
                  imageName: "prediction_engine_image_10.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "ecommerce_optimization_agency_image_4.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "data_insights_image_6.webp",
                  minBreakpoint: "250px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          version={"v2 fit-content"}
          href="/pricing/"
          //   icon={<ArrowIcon />}
          //   type={`cta`}
        >
          Get a demo
        </Button>
      </LeftRightImageText>
      <div className="pd-112-top" id="noresults,nopayment">
        <Container>
          <HeadSubhead
            version={`v2`}
            heading={`<span>15% more sales in 90 days. <br class='hidetablet hideipad hidemobile'/> Pay only if you see lift.</span>`}
            subheading={`Run 10 A/B tests for your eCommerce site, secure 3 wins, and <b>boost revenue by <br class='hidetablet hideipad hidemobile'/> 15% in just 90 days.</b> You don't pay until you see lift — and you're the sole judge.`}
          ></HeadSubhead>
          <div className="full-vw-section-wrapper image-continous-slider">
            <Image
              sourceFolder={"spzlps"}
              lazyLoad={"true"}
              fallBackImage={`3_months_image.webp`}
              // imgName={`3_months_image.webp`}
              alt={`No Results, No Payment`}
              imgName={[
                {
                  imageName: "3_months_image.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "3_months_image_1.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "3_months_image_2.webp",
                  minBreakpoint: "250px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </div>
        </Container>
      </div>
      <TopPredictions
        version="v2"
        heading="Get the top 3 predictions<br class='hidetablet hidemobile'/> for your website."
        placeholder="Email"
      />
      <ClientResults version="v2" />
      {/* <FrequentlyAskedQuestionsTyping faqs={faqEcommerceLandingPage} /> */}
      <FrequentlyAskedQuestions
        version="v2 landing-page-spz blue-bg"
        faqs={faqEcommerceLandingPage}
      />
    </div>
  )
}
