import React, { useMemo } from "react"
import Container from "../container/container"
import Image from "../../components/image-component/image-new"
import { Splide, SplideSlide } from "@splidejs/react-splide"
export default function ClientLogosLp({
  version = "v1",
  cloudinaryPath = "https://res.cloudinary.com/spiralyze/image/upload/f_auto/spiralyze2022",
  sourceFolder = "homepage",
  logos = [
    {
      name: "Logo_Netflix_2x.png",
      alt: "Netflix",
      pictureTagIndexes: [],
    },
    {
      name: "Logo_Podium_2x.png",
      alt: "Podium",
      pictureTagIndexes: [],
    },
    {
      name: "Logo_NBA_2x.png",
      alt: "NBA",
      pictureTagIndexes: [],
    },
    {
      name: "Logo_Lowes_Companies_2x.png",
      alt: "Lowes Companies",
      pictureTagIndexes: [],
    },
    { name: "Logo_Gusto_2x.png", alt: "Gusto", pictureTagIndexes: [] },
    {
      name: "Logo_Harvard_University_2x.png",
      alt: "Harvard University",
      pictureTagIndexes: [],
    },
    {
      name: "Logo_CrowdStrike_2x.png",
      alt: "CrowdStrike",
      pictureTagIndexes: [],
    },
  ],
}) {
  return (
    <div className={`socialproofwrapper ${version}`}>
      <Splide
        options={{
          arrows: false,
          pagination: false,
          type: "loop",
          autoplay: true,
          interval: 3000,
          // focus: "center",
          perPage: 5,
          perMove: 1,
          padding: "10%",
          width: "100%",
          breakpoints: {
            1360: {
              perPage: 4,
              padding: "5%",
            },
            991: {
              perPage: 3,
              padding: "10%",
            },
            767: {
              perPage: 2,
              padding: "40px",
            },
          },
        }}
        aria-label="React Splide Example"
        className="socialprooflogos lp"
      >
        {logos.map((logo, index) => {
          return (
            <SplideSlide
              key={index}
              className={`slide-item-v2 hero-slider-item lp`}
            >
              <Image
                sourceFolder={sourceFolder}
                cloudinaryPath={cloudinaryPath}
                lazyLoad={"false"}
                alt={logo?.alt ?? "Client Logos"}
                fallBackImage={logo?.name}
                imgName={logo?.name}
              />
            </SplideSlide>
          )
        })}
      </Splide>
    </div>
  )
}
