export const faqs = [
  {
    title: "<h3>What is a CRO agency?</h3>",
    desc:
      "<p>A conversion rate optimization agency is a specialized marketing agency focused on analyzing, testing, and implementing strategies to increase the number of website visitors who take desired actions. For example, if you run an e-commerce store, they can help improve your checkout process to increase sales. Similarly, if you manage a SaaS business, they might optimize your pricing page to encourage more trial sign-ups.</p>",
  },
  {
    title: "<h3>What exactly is conversion rate optimization?</h3>",
    desc:
      "<p> CRO is the process of improving your website to increase the percentage of visitors who complete a desired action, like making a purchase or filling out a form. Think of it as fine-tuning your site to make it work better for you. A CRO agency (that’s us) uses various techniques to achieve this goal, and we're here to guide you every step of the way!</p>",
  },
  {
    title:
      "<h3>Do I need a lot of traffic before I can think about optimizing for conversion?</h3>",
    desc:
      "<p>While there isn't a strict minimum, having at least 1,000 unique visitors per month is generally a good starting point. This way, you have enough data for meaningful A/B testing and analysis. We'll work with you to determine the best approach based on your current traffic levels and goals.</p>",
  },

  {
    title:
      "<h3>What is this prediction engine thing? How does it actually help my site?</h3>",
    desc:
      "<p>Our prediction engine is a fantastic tool that scrapes A/B test data from 78,000 active testing sites to identify proven winners. Using machine learning, it predicts what will work best on your site, leading to 3X higher win rates than traditional A/B testing methods employed by other CRO agencies. We're excited to bring this technology to your site to see amazing results!</p>",
  },

  {
    title:
      "<h3>Can you really guarantee results? That sounds too good to be true!</h3>",
    desc:
      "<p>This is what sets us apart from other CRO agencies. By adapting proven winners from our prediction engine, we guarantee a 38% lift in 90 days. And the best part? We don't charge any fees until we deliver the promised results. We're confident in what we do and committed to your success.</p>",
  },

  {
    title: "<h3>How does your performance pricing model work?</h3>",
    desc:
      "<p>Our CRO agency operates on a performance pricing model, meaning you don't pay any fees until we deliver the promised lift in conversions. This approach aligns our incentives with your success and ensures you only pay for results.</p>",
  },

  {
    title:
      "<h3>CRO has a lot of design, testing, and development involved. How do you handle that?</h3>",
    desc:
      "<p>We have a dedicated team of designers and developers who work together to implement effective changes. Spiralyze handles everything from initial analysis to the final implementation, ensuring a smooth and cohesive process. You can relax knowing every detail is taken care of by our experts.</p>",
  },
  {
    title: "<h3>What can I expect from your full-service team?</h3>",
    desc:
      "<p>Our full-service CRO agency takes care of everything from design and copy to development, QA, analytics, and project management. We launch your first test within two weeks and manage all aspects of implementation with our team of 170 A/B testing specialists. We're here to support you at every stage, making the process easy and stress-free.</p>",
  },
  {
    title: "<h3>How often should I be looking at my CRO strategy?</h3>",
    desc:
      "<p>CRO marketing is an ongoing process, and it's important to regularly review your strategy at least quarterly. This ensures it remains effective and aligned with your business goals. We'll continuously monitor performance and make adjustments based on new data and insights. Our CRO agency is here to help you stay ahead and keep your site performing at its best.</p>",
  },
]

export const faqsFullServiceTeam = [
  {
    title: "What makes Spiralyze different from competitors?",
    desc:
      "<p>Our conversion rate optimization experts focus on two things: what works, and getting tests running as fast as possible. </p><p>Our repository of 12,000+ past tests provides deep expertise and data on what works for you. A bias for action and the experience to get tests and analytics working on your tech stack lets us get the first test running in just 2 weeks.</p><p>Knowing what works + a repeatable process lets us deliver results, fast. </p>",
  },
  {
    title: "Do your CRO experts have experience in my industry?",
    desc:
      "<p>Yes! Our team brings specialist expertise in SaaS, digital security, HR software, fintech, consumer subscriptions, and eCommerce.</p>",
  },

  {
    title: "Can your CRO experts work with my marketing team?",
    desc:
      "<p> Absolutely! Our specialists will collaborate with your in-house teams, whether it’s demand gen, product marketing, brand, etc.</p>",
  },

  {
    title: "How accurate are your predictions?",
    desc:
      "<p>Our predictions are backed by data from 78,000 websites, weighted for your industry, goal-type, and visitor-type. We use real-world A/B test results, not guesswork, to determine what’s most likely to drive results.</p>",
  },
  {
    title: "How fast can I expect to see results?",
    desc:
      "<p>On average, our clients see their first win within 25 days. Our CRO specialists focus on proven strategies to get you results quickly.</p>",
  },

  {
    title: "Can I get a peek at how your predictive engine works?",
    desc:
      "<p> Definitely! <a href='/get-demo/'>Book a demo</a> to see how our engine scrapes data, analyzes it, and predicts the best next step for your site.</p>",
  },
  {
    title: "I've got a small business—should I even bother with A/B testing?",
    desc:
      "<p>A/B testing is valuable for every business. For small businesses, it's worth considering DIY first since it can get pricey. If you choose to work with a <a href='/'>CRO agency</a>, our team can help you decide what's best based on your goals and budget.</p>",
  },
  {
    title: "Can you guys help me with branding or rebranding, too?",
    desc:
      "<p>Yes! Our full-service team can assist with brands and rebrands. We'll help you nail down a look and message that resonates.</p>",
  },
  {
    title: "How will you keep me in the loop on how things are going?",
    desc:
      "<p>You'll get regular updates on test performance, timelines for tests being built, and learnings. Our team is available to answer questions to keep things on track.</p>",
  },
  {
    title: "What if I'm not happy with the results?",
    desc:
      "<p>If our tests don't increase your conversions, you won't pay anything. You are the sole judge of lift.</p>",
  },
]

export const faqsLandingPage = [
  {
    title:
      "<h3>Will working with a landing page optimization agency disrupt my website or slow it down?</h3>",
    desc:
      "<p>Not at all! Our A/B tests run smoothly in the background without messing with your website's performance. Our team handles all the tech, so your site stays fast and fully functional while testing is happening.</p>",
  },
  {
    title:
      "<h3>How do you ensure that A/B testing won't negatively impact my conversions?</h3>",
    desc:
      "<p>We closely monitor every test to keep things on track. Our team uses data-driven insights, including a repository of proven winners, to choose test variations that are most likely to win. If something isn’t working, we end tests quickly.</p>",
  },
  {
    title:
      "<h3>What if my landing pages don't get enough traffic for A/B testing?</h3>",
    desc:
      "<p>Even with fewer visitors, A/B testing is still possible. Ideally, a site should have around 1,000 unique visitors a month, but we use advanced methods and smaller-scale tests to drive wins even if your traffic is on the lower side.</p>",
  },
  {
    title:
      "<h3>How transparent is the process with your landing page optimization agency? Will I be kept in the loop?</h3>",
    desc:
      "<p>Yes! You'll get regular updates and detailed reports on what's working and what needs tweaking.</p>",
  },
  {
    title:
      "<h3>Can A/B testing work with my existing marketing and design strategy?</h3>",
    desc:
      "<p>Yes! Our <a target='_blank' href='https://www.spiralyze.com/full-service-team/'>conversion rate optimization experts</a> work closely with your marketing and design teams to make sure the tests align with your brand and goals. </p>",
  },
  {
    title:
      "<h3>How experienced are your landing page optimization experts?</h3>",
    desc:
      "<p>Our team consists of 170+ CRO experts who've run thousands of A/B tests across industries. From design and development to data analysis, each expert brings a unique skill set to ensure your tests are handled by the best in the field.</p>",
  },
  {
    title:
      "<h3>How do I know you're using best practices for optimizing landing pages?</h3>",
    desc:
      "<p>Our approach is grounded in the latest industry research and backed by data from over 78,000 tests. We continuously fine-tune our methods to make sure we’re always using A/B testing best practices.</p>",
  },
  {
    title:
      "<h3>Can I see case studies or examples of your past successes?</h3>",
    desc:
      "<p>We've got plenty of case studies that show off our work. These real-world success stories highlight how we've helped businesses like yours boost conversions and get the most out of your landing page tests.</p>",
  },

  {
    title: "<h3>How will you keep my data secure during testing?</h3>",
    desc:
      "<p>We follow strict privacy rules and use secure platforms to manage all tests. Your data will be fully protected and is only used to make your website better.</p>",
  },

  {
    title:
      "<h3>What kind of support can I expect during the landing page optimization process?</h3>",
    desc:
      "<p>You'll have a dedicated project manager and a whole team ready to answer your questions, share insights, and keep you updated.</p>",
  },
]

export const faqEcommerceLandingPage = [
  {
    title: "<h3>Do your CRO experts have experience in eCommerce?</h3>",
    desc:
      "<p>Our team specializes in a range of industries, including eCommerce and SaaS. This experience allows us to jump right in without wasting your time with new tech stacks and learning curves!</p>",
  },
  {
    title: "<h3>Can your team work with my marketing crew?</h3>",
    desc:
      "<p>Yes. Our specialists can seamlessly work alongside your in-house marketing and branding team. More eyeballs to look at your eCommerce site.</p>",
  },
  {
    title:
      "<h3>How do you ensure A/B tests won't disrupt my eCommerce site?</h3>",
    desc:
      "<p>Our team runs A/B tests in a way that won't disrupt your site's functionality. Every test is carefully planned to run in the background. We also terminate tests as soon as we find out they do not work. Your customers will not notice anything!</p>",
  },
  {
    title:
      "<h3>How do you choose what elements to test on my eCommerce site?</h3>",
    desc:
      "<p>We look at high-impact areas like <a target='_blank' href='https://www.spiralyze.com/landing-page-optimization-agency/'>landing pages</a>, product pages, checkout flows, and CTAs. We then use this data and compare it to our predictive engine to identify what would most likely drive conversion for your eCommerce business.</p>",
  },
  {
    title: "<h3>Can I get a peek at how this predictive engine works?</h3>",
    desc:
      "<p>Yes! Book a demo, and we'll show how it can help your eCommerce store.</p>",
  },
  {
    title: "<h3>How fast can I expect to see results?</h3>",
    desc:
      "<p>On average, our clients see their first win within 25 days. Our eCommerce-focused <a target='_blank' href='https://www.spiralyze.com/full-service-team/'>CRO experts</a> prioritize proven strategies to deliver results quickly.</p>",
  },
  {
    title: "<h3>What if I'm not happy with the results?</h3>",
    desc:
      "<p>If things aren't working, you won't be charged a dime. It's a risk-free way to see real improvements for your eCommerce business.</p>",
  },
]
