import * as React from "react"
import Layout from "../components/layout2023"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import SEO from "../components/seo"
import { HomeV2 } from "../componentsv2/homev2/homev2"
import EcommerceOptimizationAgency from "../componentsv2/ecommerceconversionoptimizationagency/ecommerceconversionoptimizationagency"

const EcommerceConversionOptimizationAgency = () => (
  <Layout nav={"hidden"} parentClass={`newtheme2024`}>
    <SEO
      title="eCommerce Conversion Optimization Agency: 15% up in 90 days
        "
      showDefaultTitle={false}
      description="Guaranteed sales boost in 90 days. Full-service eCommerce optimization with research, testing, and dev. Pay only if you see results."
    />
    {/* <LandingPageOptimizationAgencyV2 /> */}
    {/* <HomeV2 /> */}
    <EcommerceOptimizationAgency />
    <FooterV2 version="v2" />
  </Layout>
)

export default EcommerceConversionOptimizationAgency
